import React from 'react';
import { withRouter } from 'react-router-dom';

class Header extends React.Component {
  render() {
    const currentPath = this.props.location.pathname;

    return (
        <header>
            <div className="header_inner">
                <a href="/">
                    <figure className="logo">
                        <svg className="logo_svg" xmlns="http://www.w3.org/2000/svg" width="96" height="36" viewBox="0 0 96 36" alt="SEY logo">
                            <path d="M20.63 26.49c0 5.185-3.827 9.51-10.444 9.51-3.472 0-7.18-.781-10.035-2.708v-6.055c2.821 2.856 5.917 4.285 9.984 4.285 3.124 0 5.766-1.498 5.766-4.562 0-3.748-4.792-6.07-8.71-8.41C4.015 16.65 0 14.06 0 9.332 0 3.723 4.848 0 10.915 0c2.935 0 5.75.746 8.333 2.226V7.73c-2.652-2.167-5.26-3.252-8.303-3.252-3.03 0-6.316 1.257-6.316 4.11 0 2.617 2.233 4.007 5.473 5.851 5.531 3.148 10.529 5.849 10.529 12.052zm19.636-6.339h13.556v-4.504H40.266V4.931h14.06V.453H35.235v35.094h19.57v-4.478H40.266V20.151zM89.223.453l-9.533 13.11L70.285.453h-6.074L77.193 18v17.597h5.157V18L95.33.453h-6.107z" fill="#000000"/>
                        </svg>
                    </figure>
                </a>
                <nav className="navigation_primary" aria-labelledby="primary-navigation">
                    <div className="headerNav headerNav_left">
                        <a className={currentPath === '/coffees' ? 'current uppercase' : 'uppercase'} href="/coffees">Coffees</a>
                        <a className={currentPath === '/subscriptions' ? 'current uppercase' : 'uppercase'} href="/subscriptions">Subscriptions</a>
                        <a className={currentPath === '/blog' ? 'current uppercase' : 'uppercase'} href="/blog">News & Events</a>
                        <a className={currentPath === '/about' ? 'current uppercase' : 'uppercase'} href="/about">About</a>
                        <a className={currentPath === '/cafe' ? 'current uppercase' : 'uppercase'} href="/cafe">Cafe</a>
                        <a className={currentPath === '/wholesale' ? 'current uppercase' : 'uppercase'} href="/wholesale">Wholesale</a>
                    </div>
                    <div className="headerNav headerNav_right">
                        <a className={currentPath === '/account/login' ? 'current' : null} href="/account/login">Log In</a>
                        <a className={currentPath === '/cart' ? 'current' : null} href="/cart">Cart</a>
                    </div>
                </nav>
                <button aria-controls="primary-menu" aria-expanded="false" className="mobileNavButton">
                    <span></span>
                    <span></span>
                    <span></span>
                </button>
            </div>
        </header>
    );
  }
}

export default withRouter(Header);