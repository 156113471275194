import React from 'react';
import { hydrate, render } from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { createStore } from 'redux';

import rootReducer from './shared/redux/reducers';

import App from './pages/app';

const theStore = createStore(rootReducer);

const configuredApp = (
    <Provider store={theStore}>
        <BrowserRouter>
            <App />
        </BrowserRouter>
    </Provider>
);

const rootElement = document.getElementById('root');

if (rootElement.hasChildNodes()) {
    hydrate(configuredApp, rootElement);
} else {
    render(configuredApp, rootElement);
}