import React, { Component } from 'react';
import { Route, withRouter, Switch } from 'react-router-dom';

import '../style.css';

// Shared elements
import Header from '../shared/components/header';
import Footer from '../shared/components/footer';

// Pages
import Home from './home';
import Coffee from './coffee';
import Coffees from './coffees';
import Subscriptions from './subscriptions';
import NotFound from './not-found';

class App extends Component {
    render() {
        return (
            <>
                <Header/>

                <main>
                    <Switch>
                        <Route
                            exact
                            path="/"
                            render={() => (
                                <Home />
                            )}
                        />
                        <Route
                            exact
                            path="/coffees"
                            render={() => (
                                <Coffees />
                            )}
                        />
                        <Route
                            exact
                            path="/subscriptions"
                            render={() => (
                                <Subscriptions />
                            )}
                        />
                        <Route
                            exact
                            path="/products/harbegona"
                            render={() => (
                                <Coffee />
                            )}
                        />
                        <Route
                            path="*"
                            render={() => (
                                <NotFound />
                                )}
                        />
                    </Switch>
                </main>

                <Footer/>
            </>
        );
    }
}

export default withRouter(App);
