import React from 'react';

import FeaturedPlaceholder from '../shared/images/placeholder-limited.jpg';
import CoffessSubPlaceholder from '../shared/images/placeholder-coffees-subscription.png';

import ThumbnailPiedrasAmarillas from '../shared/images/thumbnail-piedras-amarillas.jpg';
import ThumbnailHarbegona from '../shared/images/thumbnail-harbegona.jpg';
import ThumbnailKiunyuAB from '../shared/images/thumbnail-kiunyu-ab.jpg';
import ThumbnailLaPlata from '../shared/images/thumbnail-la-plata.jpg';
import ThumbnailElFilo from '../shared/images/thumbnail-el-filo.jpg';
import ThumbnailFincaDanny from '../shared/images/thumbnail-finca-danny.jpg';
import ThumbnailLosAndes from '../shared/images/thumbnail-los-andes.jpg';


export default class Coffees extends React.Component {
  render() {
    return (
        <>
            <section className="content">
                <section className="coffees_preamble">
                    <h2>Our coffees</h2>
                    <p>Coffee is a medium in which we can tell a story. We are delighted to tell that story through our ever-changing, carefully curated lineup of coffees.</p>
                </section>
                <section className="coffees_limitedEdition">
                    <div className="coffees_limitedEdition_title">
                        <div className="headerWithRule">
                            <h3>Limited Edition</h3>
                        </div>
                    </div>
                    <figure>
                        <a href="/products/elida-estate">
                            <img src={FeaturedPlaceholder} alt="Featured coffee"/>
                        </a>
                    </figure>
                    <div className="coffees_limitedEdition_details">
                        <a href="/products/elida-estate">
                            <h4>Elida Estate <br/>Panama</h4>
                            <h5>Winner: Best of Panama Auction 2018</h5>
                        </a>
                        <p>The beautifully complex floral profile of this rare coffee exemplifies the Lamastus family’s century-long heritage of meticulous coffee production atop the biodiverse highlands of Panama's Volcán Barú National Park.</p>
                    </div>
                </section>
                <section className="coffees_products">
                    <div className="coffees_products_product">
                        <div className="coffees_products_product_inner">
                            <a href="/products/piedras-amarillas">
                                <figure>
                                    <img src={ThumbnailPiedrasAmarillas} alt="product thumbnail"/>
                                </figure>
                                <div className="coffees_products_product_title">
                                    <span className="coffeeTitle_farm">Piedras Amarillas</span>
                                    <span className="coffeeTitle_producer">Mario Moreno</span>
                                    <span className="coffeeTitle_varietyProcess">100% Pacas - Washed</span>
                                    <span className="coffeeTitle_country">Honduras</span>
                                </div>
                            </a>
                        </div>
                    </div>
                    <div className="coffees_products_product">
                        <div className="coffees_products_product_inner">
                            <a href="/products/kiunyu-ab">
                                <figure>
                                    <img src={ThumbnailKiunyuAB} alt="product thumbnail"/>
                                </figure>
                                <div className="coffees_products_product_title">
                                    <span className="coffeeTitle_farm">Kiunyu AB</span>
                                    <span className="coffeeTitle_varietyProcess">SL34, Ruiru 11 - Washed</span>
                                    <span className="coffeeTitle_country">Kenya</span>
                                </div>
                            </a>
                        </div>
                    </div>
                    <div className="coffees_products_product">
                        <div className="coffees_products_product_inner">
                            <a href="/products/el-filo">
                                <figure>
                                    <img src={ThumbnailElFilo} alt="product thumbnail"/>
                                </figure>
                                <div className="coffees_products_product_title">
                                    <span className="coffeeTitle_farm">El Filo</span>
                                    <span className="coffeeTitle_producer">DANNY MORENO</span>
                                    <span className="coffeeTitle_varietyProcess">100% Pacas - Washed</span>
                                    <span className="coffeeTitle_country">Honduras</span>
                                </div>
                            </a>
                        </div>
                    </div>
                    <div className="coffees_products_product">
                        <div className="coffees_products_product_inner">
                            <a href="/products/la-plata">
                                <figure>
                                    <img src={ThumbnailLaPlata} alt="product thumbnail"/>
                                </figure>
                                <div className="coffees_products_product_title">
                                    <span className="coffeeTitle_farm">La Plata</span>
                                    <span className="coffeeTitle_varietyProcess">Field Blend - Washed</span>
                                    <span className="coffeeTitle_country">Colombia</span>
                                </div>
                            </a>
                        </div>
                    </div>
                    <div className="coffees_products_product">
                        <div className="coffees_products_product_inner">
                            <a href="/products/los-andes">
                                <figure>
                                    <img src={ThumbnailLosAndes} alt="product thumbnail"/>
                                </figure>
                                <div className="coffees_products_product_title">
                                    <span className="coffeeTitle_farm">Los Andes</span>
                                    <span className="coffeeTitle_producer">Crecencio Izaguirre</span>
                                    <span className="coffeeTitle_varietyProcess">100% Pacas - Washed</span>
                                    <span className="coffeeTitle_country">Honduras</span>
                                </div>
                            </a>
                        </div>
                    </div>
                    <div className="coffees_products_product">
                        <div className="coffees_products_product_inner">
                            <a href="/products/finca-danny">
                                <figure>
                                    <img src={ThumbnailFincaDanny} alt="product thumbnail"/>
                                </figure>
                                <div className="coffees_products_product_title">
                                    <span className="coffeeTitle_farm">Finca Danny</span>
                                    <span className="coffeeTitle_producer">Jhon Wilson Poveda</span>
                                    <span className="coffeeTitle_varietyProcess">Field Blend - Washed</span>
                                    <span className="coffeeTitle_country">Columbia</span>
                                </div>
                            </a>
                        </div>
                    </div>
                    <div className="coffees_products_product">
                        <div className="coffees_products_product_inner">
                            <a href="/products/harbegona">
                                <figure>
                                    <img src={ThumbnailHarbegona} alt="product thumbnail"/>
                                </figure>
                                <div className="coffees_products_product_title">
                                    <span className="coffeeTitle_farm">Harbegona</span>
                                    <span className="coffeeTitle_producer">Ethiopian Landrace</span>
                                    <span className="coffeeTitle_varietyProcess">Washed</span>
                                    <span className="coffeeTitle_country">Ethiopia</span>
                                </div>
                            </a>
                        </div>
                    </div>
                </section>
                <section className="coffees_subscriptionPromo">
                    <figure>
                        <a href="/subscriptions">
                            <img src={CoffessSubPlaceholder} alt="Featured coffee"/>
                        </a>
                    </figure>
                    <div className="coffees_subscriptionPromo_details">
                        <div className="coffees_subscriptionPromo_details_inner">
                            <h2>SEY Subscriptions</h2>
                            <p>We curate and send to you carefully selected coffees accompanied by detailed raw product information and brew guidelines.</p>
                            <p>Shipments will include 2 50g bags of our current seasonal offerings as well as subscription- exclusive coffees.</p>
                            <a className="button" href="/subscriptions">see details</a>
                        </div>
                    </div>
                </section>
                <section className="coffees_archiveInfo">
                    <p>
                        Don’t see the coffee you’re looking for?<br/>
                        Visit our <a href="/products/archive">coffee archive</a> for a list of all previously available SEY coffees.
                    </p>
                </section>
            </section>
        </>
    );
  }
}