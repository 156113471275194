import React from 'react';

import ThumbnailHarbegona from '../shared/images/thumbnail-harbegona.jpg';

import PricingIconFarmGate from '../shared/images/pricing-icon-farmgate.svg';
import PricingIconImporter from '../shared/images/pricing-icon-importer.svg';
import PricingIconShipping from '../shared/images/pricing-icon-shipping.svg';

export default class Coffee extends React.Component {

  render() {
    return (
        <section className="content" id="coffee">
            <div className="coffee_title coffee_titleMobile">
                <span className="coffeeTitle_farm">Harbegona</span>
                <span className="coffeeTitle_varietyProcess">Ethiopian Landrace<br/>Washed</span>
                <span className="coffeeTitle_country">Ethiopia</span>
            </div>
            <div className="coffee_thumbnail">
                <figure>
                    <img src={ThumbnailHarbegona} alt="Three boxes of coffee"/>
                </figure>
            </div>
            <section className="coffee_keyInfo">
                <div className="coffee_title coffee_titleDesktop">
                    <span className="coffeeTitle_farm">Harbegona</span>
                    <span className="coffeeTitle_varietyProcess">Ethiopian Landrace<br/>Washed</span>
                    <span className="coffeeTitle_country">Ethiopia</span>
                </div>
                <div className="coffee_keyInfo_description">
                    <p>This coffee is intensely aromatic, with exquisite florals of violet and jasmine that open up to vanilla, peach and ripe melon. It is delicate and elegant with a bright, lively acidity. </p>
                </div>
                <div className="product_addToCartContainer">
                    <span className="total">$20.00</span>
                    <form method="post" action="/cart/add?design_theme_id=37829115977&amp;_sc=1&amp;frame_token=GwWtDcPENETiMZoC_pzlYSqPrW66dzchVFbYN6f_C7bS4FKxmBUBPv0AAqzrAZu6wCi8jTF6KMvbAq7aqW9_fQjpdSdb9dqqv9b2CqenLjZp_ppJqGMQSFSdrdoS32VnwiEC6DN4zYTsMxl0-V_fjg%3D%3D" id="product_form_1894701957193" accept-charset="UTF-8" enctype="multipart/form-data" novalidate="novalidate">
                        <input type="hidden" name="form_type" value="product"/>
                        <input type="hidden" name="utf8" value="✓"/>
                        <input type="number" id="Quantity-product-template" name="quantity" value="1" min="1" pattern="[0-9]*"/>
                        <button className="button" type="submit" name="add" id="AddToCart-product-template">
                            Add to cart
                        </button>
                        <input type="hidden" name="design_theme_id" value="37829115977"/>
                        <input type="hidden" name="frame_token" value="GwWtDcPENETiMZoC_pzlYSqPrW66dzchVFbYN6f_C7bS4FKxmBUBPv0AAqzrAZu6wCi8jTF6KMvbAq7aqW9_fQjpdSdb9dqqv9b2CqenLjZp_ppJqGMQSFSdrdoS32VnwiEC6DN4zYTsMxl0-V_fjg=="/>
                    </form>
                </div>
                <span className="text_small freeShippingNotification">250g bag of delicious coffee beans<br/>Weekly orders are processed on Tuesday and shipped on Thursday.</span>
            </section>
            <section className="coffee_details coffee_technicalDetails">
                <div className="coffee_technicalDetails_details">
                    <div className="coffee_technicalDetails_detail">
                        <span className="coffee_technicalDetails_detail_title">Varietal</span>
                        <p className="coffee_technicalDetails_detail_description">Ethiopian Landrace</p>
                    </div>
                    <div className="coffee_technicalDetails_detail">
                        <span className="coffee_technicalDetails_detail_title">Region</span>
                        <p className="coffee_technicalDetails_detail_description">Bensa, Sidama</p>
                    </div>
                    <div className="coffee_technicalDetails_detail">
                        <span className="coffee_technicalDetails_detail_title">Altitude</span>
                        <p className="coffee_technicalDetails_detail_description">2,300 masl</p>
                    </div>
                    <div className="coffee_technicalDetails_detail">
                        <span className="coffee_technicalDetails_detail_title">Harvest</span>
                        <p className="coffee_technicalDetails_detail_description">January, 2019</p>
                    </div>
                    <div className="coffee_technicalDetails_detail">
                        <span className="coffee_technicalDetails_detail_title">Processing</span>
                        <p className="coffee_technicalDetails_detail_description">Harvested at peak ripeness. Floated. Depulped. Wet fermented for 18-24 hours. Dried on rasied beds for 15-20 days.</p>
                    </div>
                </div>
            </section>
            <section className="coffee_details coffee_map">
                <div id="coffeeOriginMap"></div>
            </section>
            <section className="coffee_details coffee_longDescription">
                <h4>About Harbegona</h4>
                <p>Bensa, Sidama has proven itself to be an outstanding region for high quality coffee production. Harbegona comes to us under the careful oversight of Helleanna Georgalis and is an exquisite representation of this region. Our goal in Ethiopia is to begin developing relationships with individual producers, which will allow for much better physical and financial transparency. We could not be more excited for our first trip to Ethiopia this coming winter!</p>
                <h4>About Ethiopian Landrace</h4>
                <p>Ethiopia is widely acknowledged as where coffee originated, and its production continues to represent about 10% of the country’s gross domestic product. DNA testing has confirmed over 60 distinct varieties growing in Ethiopia, making it home to the most coffee biodiversity of any region in the world. Given the tradition of coffee production in Ethiopia and the political interworkings of the Ethiopian coffee trade, it is virtually impossible to get single variety coffee lots from Ethiopia. This is changing, albeit very slowly. Most Ethiopian coffees are blends of the many Ethiopian varieties, and referred to simply as 'Ethiopian Landrace'.</p>
            </section>
            <section className="coffee_details coffee_pricingDetails">
                <h4>Pricing Details</h4>
                <div className="coffee_pricingDetails_detail">
                    <h4>FOB</h4>
                    <div className="coffee_pricingDetails_detail_iconAndPrice">
                        <div className="coffee_pricingDetails_detail_iconAndPrice_icon">
                            <figure>
                                <img src={PricingIconFarmGate} alt="farm gate icon"/>
                            </figure>
                        </div>
                        <div className="coffee_pricingDetails_detail_iconAndPrice_price">
                            <h4>$3.74/lb</h4>
                        </div>
                    </div>
                </div>
                <div className="coffee_pricingDetails_detail">
                    <h4>Shipping</h4>
                    <div className="coffee_pricingDetails_detail_iconAndPrice">
                        <div className="coffee_pricingDetails_detail_iconAndPrice_icon">
                            <figure>
                                <img src={PricingIconShipping} alt="farm gate icon"/>
                            </figure>
                        </div>
                        <div className="coffee_pricingDetails_detail_iconAndPrice_price">
                            <h4>$0.30/lb</h4>
                        </div>
                    </div>
                </div>
                <div className="coffee_pricingDetails_detail">
                    <h4>OSITO (importer)</h4>
                    <div className="coffee_pricingDetails_detail_iconAndPrice">
                        <div className="coffee_pricingDetails_detail_iconAndPrice_icon">
                            <figure>
                                <img src={PricingIconImporter} alt="farm gate icon"/>
                            </figure>
                        </div>
                        <div className="coffee_pricingDetails_detail_iconAndPrice_price">
                            <h4>$0.65/lb</h4>
                        </div>
                    </div>
                </div>
                <p>The cost of getting a coffee from cherry to beverage varies enormously depending on its place of origin and the location of its consumption. The inclusion of price transparency is a starting point to inform broader conversation around the true costs of production and the sustainability of specialty coffee as a whole.</p>
            </section>
        </section>
    );
  }
}