import React from 'react';

import SubscriptionSquare from '../shared/images/subscriptions-square.jpg';

export default class Subscriptions extends React.Component {
  render() {
    return (
        <section className="content" id="subscriptions">
            <section className="subscriptions_thumbAndOptions">
                <div className="subscriptions_thumb">
                    <figure>
                        <img src={SubscriptionSquare} alt="Three boxes of coffee"/>
                    </figure>
                </div>
                <div className="subscriptions_options">
                    <h2>Monthly Coffee Subscription</h2>
                    <p>Every month we send out carefully selected coffees accompanied by detailed raw product information and brew guidelines.</p>
                    <p>Shipments will include 250g bags of our current seasonal offerings as well as subscription-exclusive coffees. Subscriptions ship on the first Wednesday of every month. Next Shipment: April 3rd, 2019!</p>
                    <form method="post" action="https://recurringcheckout.com/s/sey-coffee/checkout/recurring_product?shop_url=sey-coffee.myshopify.com" id="addToCartForm-product-template" accept-charset="UTF-8" class="shopify-product-form" enctype="multipart/form-data">
                        <input type="hidden" name="form_type" value="product"/>
                        <input type="hidden" name="utf8" value="✓"/>

                        <div class="product-single__variants">
                            <div class="selector-wrapper">
                                <label>Bags per Month</label>
                                <select class="single-option-selector" data-option="option1" id="ProductSelect-product-template-option-0">
                                    <option value="1 - $20">1 - $20</option>
                                    <option value="2 - $35">2 - $35</option>
                                    <option value="3 - $48">3 - $48</option>
                                    <option value="4 - $60">4 - $60</option>
                                </select>
                            </div>
                            <select name="id" id="ProductSelect-product-template" class="product-single__variants" style={{display: 'none'}}>
                                <option selected="selected" data-sku="SUBSCRIBE-1" value="18213495111776">1 - $20 - $20.00 USD</option>
                                <option data-sku="SUBSCRIBE-2" value="18213514510432">2 - $35 - $35.00 USD</option>
                                <option data-sku="SUBSCRIBE-3" value="18213514543200">3 - $48 - $48.00 USD</option>
                                <option data-sku="SUBSCRIBE-4" value="18213514575968">4 - $60 - $60.00 USD</option>
                            </select>
                        </div>
                        <div class="grid--uniform product-single__addtocart">
                            <div class="ro_widget">
                                <div class="bold-ro__product bold-ro__product-id-1868035948640">
                                    <div class="bold-ro__recurring-title">
                                        Subscribe and Save!
                                    </div>
                                    <div class="bold-ro__radio-div bold-ro__recurring-div bold-ro__sub bold-ro__bold-active">
                                        <label class="bold-ro__recurring-lbl">
                                            <input value="1" name="bold-ro__selector_radio_button" data-bold-recurring="" class="bold-ro__recurring-radio-btn bold-ro__subscribe-radio-btn" type="hidden" checked="" />
                                        </label>
                                        <div class="bold-ro__frequency-div">
                                            <div class="bold-ro__frequency-label">
                                                <h3>Order will ship every: 1 Month(s)</h3>
                                                <input type="hidden" name="frequency_num" class="bold-ro__frequency-num" value="1"/>
                                                <input type="hidden" name="frequency_type" class="bold-ro__frequency-type" value="3"/>
                                                <input name="frequency_type_text" class="bold-ro__frequency-type-text" type="hidden" value="Month(s)"/>
                                                <input name="group_id" class="bold-ro__group-id" type="hidden" value="79686"/>
                                                <input type="hidden" class="bold-ro__discounted-price" value="$20.00" name="discounted_price"/>
                                                <input type="hidden" class="bold-ro__discount-percentage" value="0" name="_ro_discount_percentage"/>
                                                <input type="hidden" class="bold-ro__unformatted-price" value="2000" name="_ro_unformatted_price"/>
                                                <input type="hidden" name="csrf_bold_token" value="8bee8405b957b00d2683df733ee91cc7"/>
                                                <input type="hidden" name="quantities[]" value=""/>
                                                <input type="hidden" name="product_id[]" value=""/>
                                                <input type="hidden" name="variant_id[]" value=""/>
                                                <input type="hidden" name="note" value=""/>
                                                <div class="bold-ro__prepaid-container">
                                                    <div class="bold-ro__prepaid-length" style={{display: 'none'}}>
                                                        <span class="bold-ro__limited-text">Subscription Length (# of shipments): </span>
                                                        <select name="total_recurrences" class="bold-ro__subscription-length bold-ro__prepaid-total-recurrences" disabled="true" style={{display: 'none'}}>
                                                            <option value="6">6</option>
                                                            <option value="12">12</option>
                                                        </select>
                                                        <select name="prepaid_length_id" class="bold-ro__prepaid-length-select bold-ro__subscription-length" disabled="true">
                                                            <option data-discount-percentage="10" data-length="6" value="258879">6</option>
                                                            <option data-discount-percentage="17" data-length="12" value="258880">12</option>
                                                        </select>
                                                    </div>
                                                    <div class="bold-ro__prepaid-checkbox-container">
                                                        <input type="checkbox" id="is_prepaid" name="is_prepaid" class="bold-ro__checkbox bold-ro__prepaid-checkbox" />
                                                        <label for="is_prepaid" class="bold-ro__prepaid-checkbox-lbl">Prepay for your subscription<span class="bold-ro__prepaid-discount-text"> &amp; Save <span class="bold-ro__prepaid-percent">10%</span> (<span class="bold-ro__prepaid-discount-price">$18.00</span>)</span>
                                                        </label>
                                                    </div>
                                                    <div class="bold-ro__prepaid-gift-container" style={{display: 'none'}}>
                                                        <input type="checkbox" name="is_gift" disabled="true" class="bold-ro__checkbox bold-ro__prepaid-gift-checkbox"/>
                                                        <label for="is_gift" class="bold-ro__prepaid-gift-lbl">Is this a gift?</label>
                                                    </div>
                                                    <div class="bold-ro__prepaid-total-container" style={{display: 'none'}}>
                                                        <span class="bold-ro__prepaid-total-label"></span>
                                                        <span class="bold-ro__prepaid-total">$108.00</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="product_rp_div p1868035948640"></div>
                            <span class="bold-ro__widget-loading" data-widget-loading="" style={{display: 'none'}}>
                                <span class="bold-ro__loading-container">
                                    <div data-bold-widget-loading-icon="" class="bold-ro__widget-loading-icon"></div>
                                </span>
                            </span>
                            <span className="total">$20.00</span>
                            <button class="btn button bold-ro__custombutton-1868035948640">Complete subscription</button>
                            <button type="submit" name="add" id="addToCart-product-template" class="btn btn--large btn--full cartbutton" style={{display: 'none'}}>
                                <span class="add-to-cart-text">Add to Cart</span>
                            </button>
                            <div class="bold_add_to_orders">
                            </div>
                        </div>
                        <input type="hidden" name="shopify_customer_id" value="" class="bold-ro__hidden_input shopify_customer_id bold-ro__customer_field"/>
                        <input type="hidden" name="email" value="" class="bold-ro__hidden_input email bold-ro__customer_field"/>
                        <input type="hidden" name="address1" value="" class="bold-ro__hidden_input address1 bold-ro__customer_field"/>
                        <input type="hidden" name="address2" value="" class="bold-ro__hidden_input address2 bold-ro__customer_field"/>
                        <input type="hidden" name="city" value="" class="bold-ro__hidden_input city bold-ro__customer_field"/>
                        <input type="hidden" name="company" value="" class="bold-ro__hidden_input company bold-ro__customer_field"/>
                        <input type="hidden" name="country" value="" class="bold-ro__hidden_input country bold-ro__customer_field"/>
                        <input type="hidden" name="first_name" value="" class="bold-ro__hidden_input first_name bold-ro__customer_field"/>
                        <input type="hidden" name="last_name" value="" class="bold-ro__hidden_input last_name bold-ro__customer_field"/>
                        <input type="hidden" name="phone" value="" class="bold-ro__hidden_input phone bold-ro__customer_field"/>
                        <input type="hidden" name="province" value="" class="bold-ro__hidden_input province bold-ro__customer_field"/>
                        <input type="hidden" name="zip" value="" class="bold-ro__hidden_input zip bold-ro__customer_field"/>
                    </form>
                    <span className="text_small freeShippingNotification">Free shipping. Cancel anytime.</span>
                </div>
            </section>
            <section className="subscriptions_perks">
                <div className="headerWithRule">
                    <h3>Subscriber Perks</h3>
                </div>
                <div className="subscriptions_perks_perkContainer">
                    <div className="subscriptions_perks_perk">
                        <h2>Curated Coffees</h2>
                        <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat.</p>
                    </div>
                    <div className="subscriptions_perks_perk">
                        <h2>Exclusive Seasonal Coffees</h2>
                        <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat.</p>
                    </div>
                    <div className="subscriptions_perks_perk">
                        <h2>Full control:<br/>Free shipping.<br/>Cancel Anytime.</h2>
                        <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat.</p>
                    </div>
                </div>
            </section>
        </section>
    );
  }
}