import React from 'react';

import HeroImage from '../shared/images/hero-home-temp.jpg';
import SubscriptionSquare from '../shared/images/subscriptions-square.jpg';
import EventsSquare from '../shared/images/events-square.jpg';
import ThumbnailLaPlata from '../shared/images/thumbnail-la-plata.jpg';
import ThumbnailLosAndes from '../shared/images/thumbnail-los-andes.jpg';


export default class Home extends React.Component {
  render() {
    return (
        <>
            <section className="hero">
                <div className="hero_content">
                    <h1>Drink beautiful coffee</h1>
                    <p>Explore a highlty currated lineup of seasonally rotating, single-producer, micro-lots, from around the world.</p>
                    <a className="button" href="/coffees">Shop coffee</a>
                </div>
                <figure className="hero_image">
                    <img draggable="false" src={HeroImage} alt="Home hero"/>
                </figure>
            </section>
            <section className="content">
                <section className="home_preamble">
                    <div className="home_preamble_inner">
                        <h2>Enjoy life</h2>
                        <p>We pursue ever-evolving standards of quality, economic transparancy, social responsibility, and economic sustainability while working to push the bounderies of what coffee is and can&nbsp;be.</p>
                        <div className="home_preamble_aboutLinks">
                            <a href="/cafe">about the cafe</a>
                            <a href="/about">about us</a>
                        </div>
                    </div>
                </section>
                <section className="home_subscriptions">
                    <figure>
                        <img src={SubscriptionSquare} alt="Subscriptions promo"/>
                    </figure>
                    <div className="home_subscriptions_details">
                        <h2>SEY SUBSCRIPTIONS</h2>
                        <p>Receive a curated monthly selection of coffees—including subscription-exclusives-accompanied by detailed raw product information and brew guidelines.</p>
                        <a className="button" href="/subscriptions">Find out more</a>
                    </div>
                </section>
                <section className="home_featured">
                    <div className="home_featured_photos">
                        <a className="home_featured_photos_photo" href="/products/la-plata">
                            <figure>
                                <img src={ThumbnailLaPlata} alt="Featured coffee"/>
                            </figure>
                        </a>
                        <a className="home_featured_photos_photo" href="/products/los-andes">
                            <figure>
                                <img src={ThumbnailLosAndes} alt="Featured coffee"/>
                            </figure>
                        </a>
                    </div>
                    <div className="home_featured_details">
                        <h3>Featured Coffee</h3>
                        <h2>La&nbsp;Plata & Los&nbsp;Andes</h2>
                        <p>150 character description of the two or three coffees we want to feature, 150 character description of the two or three coffees we want to feature.</p>
                        <div className="home_featured_details_ctas">
                            <a className="button" href="/products/la-plata">Shop La Plata</a>
                            <a className="button" href="/products/los-andes">Shop Los Andes</a>
                        </div>
                    </div>
                </section>
                <section className="home_events">
                    <figure>
                        <img src={EventsSquare} alt="Events promo"/>
                    </figure>
                    <div className="home_events_details">
                        <h2>Upcoming Events</h2>
                        <a className="home_events_details_event" href="/event/1">
                            <h3>
                                10.11.19<br/>
                                Tea Tasting with Song Tea & Ceramics
                            </h3>
                            <p>Song Tea takes over the bar in our cafe with an extraordinary selection of traditional, rare, and experimental teas from China and Taiwan.</p>
                        </a>
                        <a className="home_events_details_event" href="/event/1">
                            <h3>
                                10.25.19<br/>
                                Public Cupping at SEY
                            </h3>
                            <p>Sniff, slurp and discuss all of SEY's current coffee offerings.</p>
                        </a>
                        <a className="home_events_details_event" href="/event/1">
                            <h3>
                                10.31.19<br/>
                                Halloween Coffee Cupping
                            </h3>
                            <p>Something about coffee and various spooky things like spiders, mummies, student loans, and so on.</p>
                        </a>
                    </div>
                </section>
            </section>
        </>
    );
  }
}