import React from 'react';

import IconInstagram from '../images/icon-instagram.svg';

export default class Footer extends React.Component {
  render() {
    return (
        <footer>
            <div className="footer_subscribe">
                <h2>Stay Updated</h2>
                <p>Get the  latest news, offers, and event information.</p>
                <div id="mc_embed_signup">
                <form action="https://coffee.us16.list-manage.com/subscribe/post?u=1adadca5b2e3af53a1eb82cb4&amp;id=ab04d83fd7" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" className="validate" target="_blank" novalidate>
                    <div id="mc_embed_signup_scroll">
                        <input type="email" name="EMAIL" placeholder="EMAIL ADDRESS" required />
                        <div className="mc_hidden" aria-hidden="true">
                            <input type="text" name="b_1adadca5b2e3af53a1eb82cb4_ab04d83fd7" tabindex="-1" value="" />
                        </div>
                            <input className="button button-footer" type="submit" value="Subscribe" name="subscribe" id="mc-embedded-subscribe"/>
                    </div>
                </form>
                </div>
            </div>
            <div className="footer_visitContact">
                <div className="footer_visitContact_visit">
                    <h2>Visit Us</h2>
                    <p>
                        18 Grattan St Brooklyn, NY 11206<br/>
                        Weekdays 7-7  •  Weekends 8-7
                    </p>
                </div>
                <div className="footer_visitContact_contact">
                    <h2 className="uppercase">Contact Us</h2>
                    <p>
                        <a href="mailto:hello@sey.coffee">hello@sey.coffee</a>
                    </p>
                </div>
            </div>
            <div className="footer_linksSocialCopyright">
                <div className="footer_links">
                    <a href="/coffees">Coffees</a>
                    <a href="/subscriptions">Subscriptions</a>
                    <a href="/blog">News & Events</a>
                    <a href="/about">About</a>
                    <a href="/cafe">Cafe</a>
                    <a href="/coffees">Wholesale</a>
                </div>
                <div className="footer_socialCopyright">
                    <a href="https://www.instagram.com/seycoffee/" target="_blank" rel="noopener noreferrer">
                        <figure className="icon icon_instagram">
                            <img src={IconInstagram} alt="Instagram icon"/>
                        </figure>
                    </a>
                    <p>
                        ©  2019<br/>
                        BK . NY
                    </p>
                </div>
            </div>
        </footer>
    );
  }
}